













import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

import MigrationRunner from '@/store/migrations';

import SideBar from '@/components/SideBar.vue';
import NotificationSnackbar from '@/components/notifications/NotificationSnackbar.vue';
import { DateTime, Settings } from 'luxon';

interface AppTheme {
  [index: string]: any;
}

@Component({
  name: 'app',
  components: {
    SideBar,
    NotificationSnackbar,
  },
})
export default class App extends Vue {
  @State((state) => state.meta.version) readonly version!: string;

  private theme: AppTheme = {};
  private currentFontSize: number = localStorage.currentFontSize || 1;

  mounted() {
    console.log('App mounted');
    const runner = new MigrationRunner(this.version, this.$version, this.$store);
    if (!runner.runMigrations()) {
      console.error('One or more migrations failed.');
    }

    Settings.defaultZoneName = 'America/New_York';
  }

  // Computed
  get hasSideBar() {
    return !this.$route.fullPath.includes('login')
      && !this.$route.fullPath.includes('reset-password')
      && this.$route.query.minimal !== 'true';
  }

  get cssProps() {
    if (localStorage.theme) {
      this.theme = JSON.parse(localStorage.theme);
      this.$vuetify.theme.dark = this.theme.dark;
    }

    if (this.theme.backgroundColor) {
      document.body.style.background = this.theme.backgroundColor.toString();
    }

    this.$data.currentFontSize = localStorage.currentFontSize || 1;

    return {
      '--current-font-size': `${this.$data.currentFontSize}em`,
      '--light-and-dark-background': this.theme.lightAndDarkBackground,
      '--light-only-background': this.theme.lightOnlyBackground,
      '--dark-only-background': this.theme.darkOnlyBackground,
      '--odd-row': this.theme.oddRow,
      '--even-row': this.theme.evenRow,
      '--note-pad-text': this.theme.notePadText,
      '--side-bar-color': this.theme.sideBarColor,
      '--side-bar-text-color': this.theme.sideBarTextColor,
      '--text-color': this.theme.textColor,
      '--panel-color': this.theme.panelColor,
      '--panel-text-color': this.theme.panelTextColor,
      '--background-color': this.theme.backgroundColor,
      '--side-bar-selection-color': this.theme.sideBarSelectionColor,
      '--muuri-item-1': this.theme.muuriItem1,
      '--muuri-item-1-text': this.theme.muuriItem1Text,
      '--muuri-item-1-controls': this.theme.muuriItem1Controls,
      '--muuri-item-1-controls-text': this.theme.muuriItem1ControlsText,
      '--muuri-item-2': this.theme.muuriItem2,
      '--muuri-item-2-text': this.theme.muuriItem2Text,
      '--muuri-item-2-controls': this.theme.muuriItem2Controls,
      '--muuri-item-2-controls-text': this.theme.muuriItem2ControlsText,
      '--muuri-item-3': this.theme.muuriItem3,
      '--muuri-item-3-text': this.theme.muuriItem3Text,
      '--muuri-item-3-controls': this.theme.muuriItem3Controls,
      '--muuri-item-3-controls-text': this.theme.muuriItem3ControlsText,
      '--muuri-item-4': this.theme.muuriItem4,
      '--muuri-item-4-text': this.theme.muuriItem4Text,
      '--muuri-item-4-controls': this.theme.muuriItem4Controls,
      '--muuri-item-4-controls-text': this.theme.muuriItem4ControlsText,
      '--muuri-item-5': this.theme.muuriItem5,
      '--muuri-item-5-text': this.theme.muuriItem5Text,
      '--muuri-item-5-controls': this.theme.muuriItem5Controls,
      '--muuri-item-5-controls-text': this.theme.muuriItem5ControlsText,
      '--muuri-item-6': this.theme.muuriItem6,
      '--muuri-item-6-text': this.theme.muuriItem6Text,
      '--muuri-item-6-controls': this.theme.muuriItem6Controls,
      '--muuri-item-6-controls-text': this.theme.muuriItem6ControlsText,
      '--muuri-item-7': this.theme.muuriItem7,
      '--muuri-item-7-text': this.theme.muuriItem7Text,
      '--muuri-item-7-controls': this.theme.muuriItem7Controls,
      '--muuri-item-7-controls-text': this.theme.muuriItem7ControlsText,
      '--muuri-item-8': this.theme.muuriItem8,
      '--muuri-item-8-text': this.theme.muuriItem8Text,
      '--muuri-item-8-controls': this.theme.muuriItem8Controls,
      '--muuri-item-8-controls-text': this.theme.muuriItem8ControlsText,
    }
  }
}
