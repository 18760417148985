import { DateTime } from 'luxon';

import Address from '@/entities/IAddress';
import Verified from '@/entities/Verified';
import ILender, {
  IParcelState, IParcelCounty, ILoanBreakDown, IParcelBreakDown, IAgencyBreakDown,
} from '@/entities/ILender';
import FileType from '@/entities/FileType';
import Media from '@/entities/Media';
import User from '@/entities/User';
import StatusTrackable from '@/entities/StatusTrackable';

import IFile from '@/entities/IFile';

import IServiceLender, { IServiceLenderContact, IServiceRelatedLender } from '@/services/api/models/IServiceLender';

export default class Lender implements ILender {
    id: string = '';
    lenderId: string = '';
    name: string = '';
    active: boolean;
    email?: string;
    phoneNumber?: string;
    website?: string;
    notes?: string;
    faxNumber?: string;
    deleted: boolean;

    // Address
    address: Verified<Address> = new Verified<Address>({});

    // Reporting
    paperlessReporting?: boolean = false;
    paperlessReportingNotes?: string;
    paperlessReportingFormat?: Media;
    heldBill?: boolean = false;
    heldBillNotes?: string;
    escrowReportingFile?: boolean = false;
    reportVerificationFiles?: boolean = false;
    crossReferenceCoding?: boolean = false;
    lenderReportingNotes?: string;
    delinquentSearchNotes?: string;
    delinquentSearchUpdate?: boolean = false;
    updateNotes?: string;
    itSystem?: string;
    preferredFileType?: FileType;

    // Billing
    paperlessBilling: boolean = false;
    paperlessBillingNotes?: string;
    paperlessBillingFormat?: Media;
    billed?: number;
    forEvery?: number;
    serviceFee?: number;
    servicingType?: string;
    specialBillingNotes?: string;

    // Loan Info
    howLoansAreReceived?: string;
    loansAddedFrequency?: string;
    loansRemovedFrequency?: string;
    loansLastAdded?: string;
    establishedDate?: string;
    loanNotes?: string;

    // Files
    files?: IFile[] = [];

    // Contacts
    contacts: (StatusTrackable & IServiceLenderContact)[] = [];

    // Parcel state
    parcelState?: IParcelState[];
    parcelCounty?: IParcelCounty[];

    // break downs
    loanBreakDown?: ILoanBreakDown[];
    parcelBreakDown?: IParcelBreakDown[];
    agencyBreakDown?: IAgencyBreakDown[];

    // Other lenders
    relatedLenders: (StatusTrackable & IServiceRelatedLender)[] = [];

    // Verification
    lenderVerified: boolean = false;
    lenderVerifiedBy?: User;
    lenderVerifiedOn?: Date;

    get totalLoanCount(): number {
      if (!this.loanBreakDown) {
        return 0;
      }

      return this.loanBreakDown.reduce((totalCount, loanEntry) => totalCount + loanEntry.count, 0);
    }

    get totalEscrowLoanCount(): number {
      if (!this.loanBreakDown) {
        return 0;
      }

      const entry = this.loanBreakDown.find((value) => value.loanType === 'E')

      return entry ? entry.count : 0;
    }

    get totalNonEscrowLoanCount(): number {
      if (!this.loanBreakDown) {
        return 0;
      }

      const entry = this.loanBreakDown.find((value) => value.loanType === 'N')

      return entry ? entry.count : 0;
    }

    get totalBothLoanCount(): number {
      if (!this.loanBreakDown) {
        return 0;
      }

      const entry = this.loanBreakDown.find((value) => value.loanType === 'EN')

      return entry ? entry.count : 0;
    }

    get totalParcelCount(): number {
      if (!this.parcelBreakDown) {
        return 0;
      }

      return this.parcelBreakDown.reduce((totalCount, parcelEntry) => totalCount + parcelEntry.count, 0);
    }

    get totalEscrowParcelCount(): number {
      if (!this.parcelBreakDown) {
        return 0;
      }

      const entry = this.parcelBreakDown.find((value) => value.parcelType === 'E')

      return entry ? entry.count : 0;
    }

    get totalNonEscrowParcelCount(): number {
      if (!this.parcelBreakDown) {
        return 0;
      }

      const entry = this.parcelBreakDown.find((value) => value.parcelType === 'N')

      return entry ? entry.count : 0;
    }

    get totalBothParcelCount(): number {
      if (!this.parcelBreakDown) {
        return 0;
      }

      const entry = this.parcelBreakDown.find((value) => value.parcelType === 'EN')

      return entry ? entry.count : 0;
    }

    constructor(apiLender: IServiceLender) {
      this.contacts = [];

      this.lenderId = apiLender.lender_id;
      this.id = apiLender.lender_number;
      this.name = apiLender.name;
      this.active = apiLender.active;

      // Address w/ verification parameters
      let addressVerifyUser;
      if (apiLender.address_verified && apiLender.address_verified_by) {
        const {
          id, given_name, family_name, email,
        } = apiLender.address_verified_by;

        addressVerifyUser = new User(id, given_name, family_name, email);
      }

      this.address = new Verified<Address>({
        address1: apiLender.address_1,
        address2: apiLender.address_2,
        city: apiLender.city,
        state: apiLender.state,
        zipCode: apiLender.zip_code,
      }, apiLender.address_verified, addressVerifyUser, apiLender.address_verified_on ? DateTime.fromISO(apiLender.address_verified_on).toJSDate() : undefined);

      this.email = apiLender.email;
      this.phoneNumber = apiLender.phone_number;
      this.servicingType = apiLender.loan_type;

      this.notes = apiLender.notes;
      this.website = apiLender.website;
      this.paperlessReporting = apiLender.paperless_reporting;
      this.paperlessReportingFormat = apiLender.paperless_reporting_format ? apiLender.paperless_reporting_format as Media : undefined;
      this.paperlessReportingNotes = apiLender.paperless_reporting_notes;
      this.heldBill = apiLender.held_bill;
      this.heldBillNotes = apiLender.held_bill_notes;
      this.delinquentSearchUpdate = apiLender.delinquent_search_update;
      this.delinquentSearchNotes = apiLender.delinquent_search_notes;
      this.updateNotes = apiLender.update_notes;
      this.lenderReportingNotes = apiLender.lender_reporting_notes;
      this.itSystem = apiLender.it_system;
      this.preferredFileType = apiLender.preferred_file_type ? apiLender.preferred_file_type as FileType : undefined;
      this.escrowReportingFile = apiLender.escrow_reporting_file;
      this.reportVerificationFiles = apiLender.report_verification_files;
      this.crossReferenceCoding = apiLender.cross_reference_coding;
      this.billed = apiLender.billed;
      this.forEvery = apiLender.for_every;
      this.serviceFee = apiLender.service_fee;
      this.specialBillingNotes = apiLender.special_billing_notes;
      this.paperlessBilling = apiLender.paperless_billing;
      this.paperlessBillingFormat = apiLender.paperless_billing_format ? apiLender.paperless_billing_format as Media : undefined;
      this.paperlessBillingNotes = apiLender.paperless_billing_notes;
      this.howLoansAreReceived = apiLender.how_loans_are_received;
      this.loansAddedFrequency = apiLender.loans_added_frequency;
      this.loansRemovedFrequency = apiLender.loans_removed_frequency;
      this.loansLastAdded = apiLender.loans_last_added ? DateTime.fromISO(apiLender.loans_last_added).toFormat('MM/dd/yyyy') : undefined;
      this.establishedDate = apiLender.established_date ? DateTime.fromISO(apiLender.established_date).toFormat('MM/dd/yyyy') : undefined;
      this.loanNotes = apiLender.loan_notes;
      this.deleted = apiLender.deleted;

      let verifyUser;
      if (apiLender.lender_verified && apiLender.lender_verified_by) {
        const {
          id, given_name, family_name, email,
        } = apiLender.lender_verified_by;

        verifyUser = new User(id, given_name, family_name, email);
      }
      this.lenderVerified = apiLender.lender_verified;
      this.lenderVerifiedBy = verifyUser;
      this.lenderVerifiedOn = apiLender.lender_verified_on ? DateTime.fromISO(apiLender.lender_verified_on).toJSDate() : undefined;

      this.files = apiLender.files || [];
      this.relatedLenders = apiLender.related_lenders || [];
      this.parcelState = apiLender.parcel_state || [];
      this.parcelCounty = apiLender.parcel_county || [];
      if (apiLender.loan_break_down && apiLender.loan_break_down.length) {
        this.loanBreakDown = apiLender.loan_break_down.map((lbd) => ({
          loanType: lbd.loan_type,
          count: lbd.count,
        }))
      } else {
        this.loanBreakDown = [];
      }
      if (apiLender.parcel_break_down && apiLender.parcel_break_down.length) {
        this.parcelBreakDown = apiLender.parcel_break_down.map((lbd) => ({
          parcelType: lbd.parcel_type,
          count: lbd.count,
        }))
      } else {
        this.parcelBreakDown = [];
      }
      if (apiLender.agency_break_down && apiLender.agency_break_down.length) {
        this.agencyBreakDown = apiLender.agency_break_down.map((abd) => ({
          capAgency: abd.cap_agency,
          name: abd.name,
          county: abd.county,
          state: abd.state,
          count: abd.count,
        }))
      } else {
        this.agencyBreakDown = [];
      }
      this.contacts = apiLender.contacts || [];

      Lender.upper(this);
    }

    static upper(obj: any): any {
      if (!obj) return obj;

      Object.keys(obj).forEach((prop: string) => {
        if (prop === 'type' || prop === 'url' || prop === 'website' || prop === 'email') return;
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          if (typeof obj[prop] === 'string') {
            obj[prop] = obj[prop].toUpperCase();
          }
          if (typeof obj[prop] === 'object') {
            this.upper(obj[prop]);
          }
        }
      });

      return obj;
    }
}
