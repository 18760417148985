enum FilterType {
  date = 'date',
  lenders = 'lenders',
  loans = 'loans',
}

interface FilterConfig {
  type: FilterType;
  description?: string;
}

export interface ReportConfig {
  name: string;
  description?: string;
  filters?: FilterConfig[];
}

export const capitalReportConfigs = [
  {
    name: 'Add Parcel Report',
    description: 'Assign parcels for loans with no parcel number.',
  },
  {
    name: 'Add Tax Office Report',
    description: 'Create a report that pulls properties without a tax agency assigned.',
  },
  {
    name: 'Agency Data Report',
    description: 'Create tax office data reports within an agency.',
  },
  {
    name: 'Agency Label Report',
    description: 'Create mailing address labels for tax agencies.',
  },
  {
    name: 'Audit Report',
    description: 'Create a lender delinquent tax report.',
  },
  {
    name: 'Audit Search',
    description: 'Create a field search for delinquent tax reports.',
  },
  {
    name: 'Delinquent Memo Bill',
    description: 'Create delinquent memorandum tax bills.',
  },
  {
    name: 'Escrow Balance Sheet',
    description: 'Create a cover sheet for escrow tax payments.',
  },
  {
    name: 'Escrow ID Sheet',
    description: 'Create a tax bill check in sheet for escrow properties.',
  },
  {
    name: 'Escrow Memo Bill',
    description: 'Create memorandum tax bill data for escrow properties.',
  },
  {
    name: 'Lender Data Report',
    description: 'Create custom lender data reports.',
  },
  {
    name: 'Loan Data Report',
    description: 'Create custom loan data reports.',
  },
  {
    name: 'Pack List',
    description: 'Create cover sheet listings for escrow balance sheets by lender.',
  },
  {
    name: 'Parcel Format Flag',
    description: 'Displays parcel numbers that are not compatible with their tax agencies\' parcel formats.',
  },
  {
    name: 'Parcels Not Verified',
    description: 'Displays loans with parcels that need to be verified.',
  },
  {
    name: 'Tax Bill Request',
    description: 'Create a tax bill request for escrow properties.',
  },
  {
    name: 'Tax Data Sheet',
    description: 'Create individual loan/parcel delinquent tax reports.',
  },
];

export const lenderReportConfigs: ReportConfig[] = [
  {
    name: 'Lender Data Report',
    description: 'For lender Create custom loan data reports.',
  },
  {
    name: 'Loan Data Report',
    description: 'Create custom loan data reports.',
  },
  {
    name: 'Add Parcel Report',
    description: 'Assign parcels for loans with no parcel number.',
  },
  {
    name: 'Agency Label Report',
    description: 'Create mailing address labels for tax agencies.',
  },
  {
    name: 'Audit Report',
    description: 'Create a lender delinquent tax report.',
  },
  {
    name: 'Escrow Balance Sheet',
    description: 'Create a cover sheet for escrow tax payments.',
  },
  {
    name: 'Tax Data Sheet',
    description: 'Create individual loan/parcel delinquent tax reports.',
  },
];

const defaultReports: ReportConfig[] = [
  {
    name: 'Billing Report',
    description: 'Create billing and invoice reports.',
    filters: [{
      type: FilterType.date,
    }, {
      type: FilterType.lenders,
    }, {
      type: FilterType.loans,
    }],
  },
  {
    name: 'Modify Reported Date Utility',
    description: 'Modify the reported dates on escrow and non-escrow parcels.',
  },
];

export default defaultReports.concat(capitalReportConfigs);
