import Vue from 'vue';
import { DateTime } from 'luxon';

function convertToFormat(date: Date | string | DateTime, format: string): string {
  if (!date) {
    return '';
  }

  if (date instanceof DateTime) {
    return date.toFormat(format);
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).toFormat(format);
  }

  if (DateTime.fromFormat(date, 'MM/dd/yyyy').isValid) {
    return DateTime.fromFormat(date, 'MM/dd/yyyy').toFormat(format);
  }

  const dateTime = DateTime.fromISO(date).toFormat(format);
  return dateTime === 'Invalid DateTime' ? date : dateTime;
}

Vue.filter('casualDate', (date: Date | string) => convertToFormat(date, 'MMMM d, yyyy'))

Vue.filter('commonDate', (date: Date | string) => convertToFormat(date, 'MM/dd/yyyy'));

Vue.filter('commonDateNoYear', (date: Date | string) => convertToFormat(date, 'MM/dd'));

Vue.filter('commonDateWithTime', (date: Date | string) => convertToFormat(date, 'f'));

Vue.filter('specificTime', (date: Date) => {
  const message = DateTime.fromJSDate(date);
  const today = DateTime.local();

  if (message.hasSame(today, 'day') && message.hasSame(today, 'month') && message.hasSame(today, 'year')) {
    return message.toLocaleString(DateTime.TIME_SIMPLE);
  }

  return message.toLocaleString();
});
