import Vue from 'vue';
import {
  Module, GetterTree, MutationTree, ActionTree,
} from 'vuex';

import User from '@/entities/User';
import UserService from '@/services/users';
import { JsonPatchOperator } from '@/helpers/vuelidateToPatch';

import {
  LOGIN, LOGOUT, SET_USER, RESET_PASSWORD, SET_LOADING,
} from './mutationTypes';

export interface UserStore {
  user?: User,
  loggedIn: boolean,

  loading: boolean,
}

const userService = new UserService();

const defaultState: UserStore = {
  user: undefined,
  loggedIn: false,
  loading: false,
};

const getters: GetterTree<UserStore, any> = {
  getUser: (state) => () => state.user,
  getRoles: (state) => () => (state.user ? state.user.roles : []),
}

const mutations: MutationTree<UserStore> = {
  [LOGIN]: (state) => {
    state.loggedIn = true;
  },
  [LOGOUT]: (state) => {
    state.loggedIn = false;
  },
  [RESET_PASSWORD]: (state) => {
    state.loggedIn = false;
  },
  [SET_USER]: (state, payload) => {
    state.user = payload;
  },
  [SET_LOADING]: (state, payload) => {
    state.loading = payload;
  },
}

const actions: ActionTree<UserStore, any> = {
  async login({ commit, state }, payload) {
    try {
      await userService.login(payload.username, payload.password);

      commit(LOGIN);

      const response = await userService.getOwnUser();
      const oldEmail = (state.user && state.user.email) || null;

      commit(SET_USER, response);

      // If this is a new user, reset the dashboard
      if (!state.user || oldEmail !== response.email) {
        this.dispatch('resetPanels');
      }
    } catch (e) {
      console.log(e)
      commit(LOGOUT);
    }
  },

  async logout({ commit }) {
    try {
      await userService.logout();
    } catch (e) {
      console.log('Logout failed.')
    }
    Vue.$cookies.remove('sid');
    commit(LOGOUT);
  },

  async resetPassword({ commit }, payload) {
    await userService.resetPassword(payload);
    commit(RESET_PASSWORD);
  },

  async fetchUser({ commit, state }) {
    if (state.loading) {
      return;
    }

    try {
      commit(SET_LOADING, true);
      const response = await userService.getOwnUser();

      commit(SET_USER, response);

      return;
    } catch (e) {
      commit(LOGOUT);
    } finally {
      commit(SET_LOADING, false);
    }
  },

  async updateNotes({ dispatch }, payload: string) {
    const newUser = await userService.patchOwnUser([{
      op: JsonPatchOperator.replace,
      path: '/notes',
      value: payload,
    }]);

    dispatch('fetchUser');
  },
}

const userModule: Module<UserStore, any> = {
  state: defaultState,
  getters,
  mutations,
  actions,
};

export { userModule as default };
