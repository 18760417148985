import LenderApiService from '@/services/api/LenderApiService';
import Lender from '@/entities/Lender';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';

interface LenderCollection {
  count: number,
  lenders: Lender[],
}

interface LenderSearchParams {
  limit?: number,
  offset?: number,
  order_by?: string,
  order_by_desc?: boolean,
  search_field?: string,
  search_value?: string,
  agency_selector_search?: boolean,
}

class LenderService {
  private lastRetrieval: string;

  async getAllLenders(params?: LenderSearchParams): Promise<LenderCollection> {
    const response = await LenderApiService.getAllLenders(params);

    return {
      count: parseInt(response.count, 10),
      lenders: response.lenders.map((lender) => new Lender(lender)),
    };
  }

  async getLender(id: string): Promise<Lender> {
    const response = await LenderApiService.getLender(id);
    this.lastRetrieval = response.headers.etag;

    return new Lender(response.data);
  }

  async updateLender(id: string, payload: JsonPatchPayload): Promise<Lender> {
    const response = await LenderApiService.updateLender(id, payload, this.lastRetrieval);
    this.lastRetrieval = response.headers.etag;
    return new Lender(response.data);
  }

  async addLender(payload: any): Promise<Lender> {
    const response = await LenderApiService.addLender(payload);
    return new Lender(response);
  }

  async uploadFile(id: string, file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await LenderApiService.uploadFile(id, formData);
  }

  async uploadPicture(id: string, file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await LenderApiService.uploadPicture(id, formData);
  }

  async deleteLender(id: string): Promise<any> {
    const response = await LenderApiService.deleteLender(id);

    return new Lender(response);
  }

  async batchPatchLenders(payload: JsonPatchPayload): Promise<any> {
    const response = await LenderApiService.batchPatchLenders(payload);
    return response;
  }
}

export { LenderService as default };
