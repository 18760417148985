import User from '@/entities/User';
import UserApiService from '@/services/api/UserApiService';
import IServiceUser from '@/services/api/models/IServiceUser';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';
import Query from '@/entities/queries/Query';
import Message from '@/entities/messages/Message';
import { MessageType } from '@/services/api/models/IServiceMessage';
import { IServiceQueryDefinition } from '@/services/api/models/IServiceQuery';

type UserUpdate = Partial<Omit<IServiceUser, 'last_login' | 'person_id'>>;
export interface QueryPost {
  name: string,
  description?: string,
  tags?: string[],
  query: IServiceQueryDefinition,
  creator_id?: string,
}

export interface MessagePost {
  target_id: string,
  type: MessageType,
  body?: string,
  message?: string,
  query?: IServiceQueryDefinition,
}

class UserService {
  async login(username: string, password: string): Promise<void> {
    await UserApiService.login(username, password);
  }

  async logout(): Promise<void> {
    await UserApiService.logout();
  }

  async resetPassword(email: string): Promise<void> {
    await UserApiService.resetPassword(email);
  }

  async getOwnUser(): Promise<User> {
    const response = await UserApiService.getOwnUser();

    return new User(response);
  }

  async getUsers(search?: string): Promise<User[]> {
    const response = await UserApiService.getUsers(search);

    return response.map((serviceUser) => new User(serviceUser));
  }

  async getUserById(id: string): Promise<User> {
    const response = await UserApiService.getUserById(id);

    return new User(response);
  }

  async createUser(payload: Partial<IServiceUser>): Promise<User> {
    const response = await UserApiService.createUser(payload);

    return new User(response.id, response.given_name, response.family_name, response.email, response.lender_id, response.roles);
  }

  async updateUser(id: string, payload: UserUpdate): Promise<void> {
    await UserApiService.updateUser(id, payload);
  }

  async deleteUser(id: string): Promise<User> {
    const response = await UserApiService.deleteUser(id);
    return new User(response.id, response.given_name, response.family_name, response.email, response.lender_id, response.roles);
  }

  async patchOwnUser(payload: JsonPatchPayload): Promise<IServiceUser> {
    return UserApiService.patchOwnUser(payload);
  }

  async patchUser(id: string, payload: JsonPatchPayload): Promise<IServiceUser> {
    return UserApiService.patchUser(id, payload);
  }

  async uploadProfilePic(file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await UserApiService.uploadFile('profile', formData);
  }

  async uploadCoverPic(file: File): Promise<void> {
    const formData: FormData = new FormData();
    formData.set('file', file);
    await UserApiService.uploadFile('cover', formData);
  }

  async logoutUser(id: string): Promise<void> {
    await UserApiService.logoutUser(id);
  }

  async getAllUserQueries(): Promise<Query[]> {
    const response = await UserApiService.getAllUserQueries();
    return response.map((query) => new Query(query));
  }

  async getUserQuery(id: string): Promise<Query> {
    const response = await UserApiService.getUserQuery(id);
    return new Query(response);
  }

  async updateUserQuery(id: string, payload: JsonPatchPayload): Promise<Query> {
    const response = await UserApiService.updateUserQuery(id, payload);
    return new Query(response);
  }

  async addUserQuery(payload: QueryPost): Promise<Query> {
    const response = await UserApiService.addUserQuery(payload);
    return new Query(response);
  }

  async deleteUserQuery(id: string): Promise<void> {
    await UserApiService.deleteUserQuery(id);
  }

  async getUserInbox(): Promise<Message[]> {
    const response = await UserApiService.getUserInbox();
    return response.map((message) => new Message(message));
  }

  async getUserSent(): Promise<Message[]> {
    const response = await UserApiService.getUserSent();
    return response.map((message) => new Message(message));
  }

  async deleteMessage(id: string): Promise<void> {
    await UserApiService.deleteMessage(id);
  }

  async deleteSentBatch(id: string): Promise<void> {
    await UserApiService.deleteSentBatch(id);
  }

  async markAsRead(id: string): Promise<Message> {
    const response = await UserApiService.markAsRead(id, true);
    return new Message(response);
  }

  async addMessage(payload: MessagePost, files: File[] = []): Promise<void> {
    const fileFormData = files.reduce((formData, file, index) => {
      formData.set(`file_${index}`, file);
      return formData;
    }, new FormData());
    fileFormData.set('data', JSON.stringify(payload));

    await UserApiService.addMessage(fileFormData);
  }
}

export { UserService as default };
