import Axios, { AxiosResponse } from 'axios';

import IServiceLender from '@/services/api/models/IServiceLender';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';

interface ServicesResponse {
  count: any,
  lenders: IServiceLender[],
}

export default {
  async getAllLenders(params?: any): Promise<ServicesResponse> {
    const response = await Axios.get<ServicesResponse>('/lenders', {
      params,
    });

    return response.data;
  },

  async getLender(id: string): Promise<AxiosResponse> {
    return Axios.get<IServiceLender>(`/lenders/${id}`);
  },

  async updateLender(id: string, payload: JsonPatchPayload, etag: string): Promise<AxiosResponse> {
    return Axios.patch<IServiceLender>(`/lenders/${id}`, payload, {
      headers: {
        'If-Match': etag,
      },
    });
  },

  async addLender(payload: any): Promise<any> {
    const response = await Axios.post<IServiceLender, AxiosResponse<IServiceLender>>('/lenders', payload);
    return response.data;
  },

  async uploadFile(id: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/lenders/${id}/files`, formData);
  },

  async uploadPicture(id: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/lenders/${id}/files/profile`, formData);
  },

  async deleteLender(id: string): Promise<any> {
    const response = await Axios.delete<any>(`/lenders/${id}`);

    return response.data;
  },

  async batchPatchLenders(payload: JsonPatchPayload): Promise<void> {
    const response = await Axios.patch<void>('/lenders', payload);
    console.log(response)
    return response.data;
  },
};
