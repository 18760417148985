
import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { Action, State } from 'vuex-class';

import User from '@/entities/User';
import { intersection } from 'lodash';

// NOTE: This component is meant to provide convenience UI functionality. You can tell that it is easy to potentially mock a role to see elements.
// The backend endpoints are still permissions-enforced.
@Component({
  name: 'user-permissions',
})
export default class UserPermissions extends Vue {
  @State((state) => state.user.user) user!: User;

  @Action('fetchUser') fetchUser!: () => void;

  // Computed
  get isAdmin(): boolean {
    return this.hasRole('Capital Admin');
  }

  get isUser(): boolean {
    return this.hasRole('Capital User') || this.isAdmin;
  }

  get isTrainee(): boolean {
    return this.hasRole('Capital Trainee') || this.isUser;
  }

  get isExactlyUser(): boolean {
    return this.hasRole('Capital User');
  }

  get isExactlyTrainee(): boolean {
    return this.hasRole('Capital Trainee');
  }

  get isLenderAdmin(): boolean {
    return this.hasRole('Lender Admin');
  }

  get isLenderUser(): boolean {
    return this.hasRole('Lender User') || this.isLenderAdmin;
  }

  get isExactlyLenderUser(): boolean {
    return this.hasRole('Lender User');
  }

  get isCapitalUserOrTrainee(): boolean {
    return this.hasRole('Capital Trainee') || this.hasRole('Capital User');
  }

  // Hooks
  async created() {
    await this.fetchUser();
  }

  hasRole(name: string | string[]): boolean {
    const allNames = Array.isArray(name) ? name : [name];
    console.log(allNames, this.user.roles.map((role) => role.name))
    return this.user
      ? intersection(allNames, this.user.roles.map((role) => role.name)).length > 0
      : false;
  }

  hasScope(scope: string): boolean {
    return this.user ? this.user.roles.findIndex(
      (role) => role.scopes.findIndex((roleScope) => roleScope.toString() === scope) !== -1,
    ) !== -1 : false;
  }
}
