import { Store } from 'vuex';
import { cloneDeep } from 'lodash';

import { AppStore } from '@/store';

const migration = {
  from: '0.1.0',
  to: '0.1.1',
  migration: (store: Store<AppStore>) => {
    const currentPanels = cloneDeep(store.state.dashboard.panels ? store.state.dashboard.panels : []);

    // Fix the links
    currentPanels.forEach((panel) => {
      switch (panel.component) {
        case 'inbox':
          panel.route = 'messages';
          break;

        case 'loans':
          panel.route = 'loans';
          break;

        case 'reports':
          panel.route = 'reports';
          break;

        case 'lenders-detail':
        case 'lenders':
          panel.route = 'lenders';
          break;

        case 'agencies':
          panel.route = 'agencies';
          break;

        default:
          break;
      }
    });

    store.dispatch('savePanels', currentPanels);
  },
};

export { migration as default };
