import { Validation } from 'vuelidate';
import { snakeCase } from 'change-case';
import _ from 'lodash';

// This regex is used to force address1 to become address_1
const alphaToNumRegex = /([a-z])([A-Z0-9])/g;

export enum JsonPatchOperator {
  add = 'add',
  remove = 'remove',
  replace = 'replace',
  move = 'move',
  copy = 'copy',
  test = 'test',
}

export interface JsonPatchEntry {
  op: JsonPatchOperator,
  path: string,
  from?: string,
  value?: any,
}

// Ignore next 3 types - keeping for later
type ValidationProperties<V> = {
  [P in keyof V]?: Validation & ValidationProperties<V[P]> & ValidationEvaluation
}

interface ValidationGroups {
  [groupName: string]: Validation & ValidationProperties<any>
}

interface ValidationEvaluation {
  [ruleName: string]: boolean
}

interface JsonPatchConversionHandler<T = any> {
  key: string;
  converter: (value: T) => JsonPatchEntry | JsonPatchEntry[],
}

export type JsonPatchPayload = JsonPatchEntry[];

export function simpleVerifiedConverter(value: any, field: string) {
  const verifiedKey = 'verified';
  const patchEntries: JsonPatchEntry[] = [];

  patchEntries.push({
    op: JsonPatchOperator.replace,
    path: `/${snakeCase(field, {
      splitRegexp: alphaToNumRegex,
    })}`,
    value: (value.$model[verifiedKey] && value.$model[verifiedKey].id) || value.$model[verifiedKey],
  });

  return patchEntries;
}

export function verifiedAddressConverter(value: any, addressKeyMap: any = {}, prefix?: string): JsonPatchEntry | JsonPatchEntry[] {
  const addressKeys = Object.keys(value.$model.value);
  const verifiedKeys = ['verified'];
  const patchEntries: JsonPatchEntry[] = [];

  const verifiedPrefix = prefix ? `${prefix}_address` : 'address';

  verifiedKeys.forEach((key) => {
    if (value[key] && value[key].$dirty) {
      patchEntries.push({
        op: JsonPatchOperator.replace,
        path: `/${verifiedPrefix}_${snakeCase(key, {
          splitRegexp: alphaToNumRegex,
        })}`,
        value: (value.$model[key] && value.$model[key].id) || value.$model[key],
      });
    }
  });

  const valuePrefix = prefix ? `${prefix}_` : '';

  addressKeys.forEach((key) => {
    const finalKey = addressKeyMap[key] || key;
    if (value.value[key] && value.value[key].$dirty) {
      patchEntries.push({
        op: JsonPatchOperator.replace,
        path: `/${valuePrefix}${snakeCase(finalKey, {
          splitRegexp: alphaToNumRegex,
        })}`,
        value: value.$model.value[key],
      });
    }
  });

  return patchEntries;
}

export function customAddressConverter(addressKeyMap: any, prefix?: string) {
  return (value: any): JsonPatchEntry | JsonPatchEntry[] => verifiedAddressConverter(value, addressKeyMap, prefix);
}

export function userUuidConverter(value: any) {
  if (value.verifiedBy && value.verifiedBy.$dirty) {
    return {
      op: JsonPatchOperator.replace,
      path: '/verified_by',
      value: value.$model.verifiedBy,
    };
  }

  return [] as JsonPatchEntry[];
}

function vuelidateCollectionToPatch(vuelidate: any, param: string, handlers: JsonPatchConversionHandler[] = [], collectionKeys: { [key: string]: string | { key: string, converters: JsonPatchConversionHandler[] } } = {}): JsonPatchPayload {
  if (!vuelidate.$each) {
    throw new TypeError('Vuelidate object does not have an $each validator.');
  } else if (!Array.isArray(vuelidate.$model)) {
    throw new TypeError('Validator model is not an array.');
  }

  const { length } = vuelidate.$model;
  const allPatches = [];

  for (let index = 0; index < length; index += 1) {
    const element = vuelidate.$each[index];
    const model = vuelidate.$model[index];

    if (model.$new === undefined || !model.$new) {
      const collectionEntry = collectionKeys[param];
      const idKey = typeof collectionEntry === 'string' ? collectionEntry : collectionEntry.key;
      const collectionHandlers = typeof collectionEntry === 'string' ? [] : collectionEntry.converters;
      const rootPatches = vuelidateToPatch(element, collectionHandlers, collectionKeys); // eslint-disable-line

      // Make sure unneccessary undefined
      rootPatches.forEach((patch) => {
        patch.path = `/${snakeCase(param, {
          splitRegexp: alphaToNumRegex,
        })}/${element.$model[idKey]}${patch.path}`;
      });

      allPatches.push(...rootPatches);
    }
  }

  return allPatches;
}

function vuelidateToPatch(vuelidate: any, handlers: JsonPatchConversionHandler[] = [], collectionKeys: { [key: string]: string | { key: string, converters: JsonPatchConversionHandler[] } } = {}): JsonPatchPayload {
  // If the root validation object does not report any fields as dirty, stop now
  if (!vuelidate.$anyDirty) {
    return [];
  }

  const dirtyFields: any[] = _.chain(Object.keys(vuelidate.$params))
    .filter((param: string) => {
      const fieldValidation: Validation = vuelidate[param];
      return fieldValidation.$dirty || fieldValidation.$anyDirty;
    })
    .map((param: string) => ({
      validation: vuelidate[param],
      param,
    }))
    .value();

  const entryMatrix = dirtyFields.map((field: any): JsonPatchEntry | JsonPatchEntry[] => {
    const conversionHandler = handlers.find((handler) => handler.key === field.param);

    if (field.validation.$each) {
      return vuelidateCollectionToPatch(field.validation, field.param, handlers, collectionKeys);
    }

    if (conversionHandler) {
      return conversionHandler.converter(field.validation)
    }

    return {
      op: JsonPatchOperator.replace,
      path: `/${snakeCase(field.param, {
        splitRegexp: alphaToNumRegex,
      })}`,
      value: field.validation.$model,
    };
  });

  return entryMatrix.flat(2);
}

export function currencyFieldConverter(value: Validation, field: string) {
  const patchEntries: JsonPatchEntry[] = [];

  patchEntries.push({
    op: JsonPatchOperator.replace,
    path: `/${snakeCase(field, {
      splitRegexp: alphaToNumRegex,
    })}`,
    value: value.$model ? value.$model.replace(/\$|,/g, '') : null,
  });

  return patchEntries;
}

export function falsyNullConverter(value: Validation, field: string) {
  const patchEntries: JsonPatchEntry[] = [];

  patchEntries.push({
    op: JsonPatchOperator.replace,
    path: `/${snakeCase(field, {
      splitRegexp: alphaToNumRegex,
    })}`,
    value: value.$model ? value.$model : null,
  });

  return patchEntries;
}

export { vuelidateToPatch as default };
