import {
  Module, MutationTree, ActionTree,
} from 'vuex';
import { UPDATE_CONFIG } from './mutationTypes';

export interface ConfigStore {
  roundedPanels: boolean,
  fontSize: number,
  activeTheme: any,
}

const defaultState: ConfigStore = {
  roundedPanels: false,
  fontSize: 1,
  activeTheme: {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    // Above are built in colors
    sideBarColor: '#38444f',
    sideBarTextColor: 'white',
    sideBarSelectionColor: '#88a2c4',
    textColor: '#353535',
    panelColor: '#c9c9c9',
    panelTextColor: '#000000',
    backgroundColor: '#ffffff',
    muuriItem1: '#ffffff',
    muuriItem1Text: '#000000',
    muuriItem1Controls: '#b5b0a9',
    muuriItem1ControlsText: '#000000',
    muuriItem2: '#ffffff',
    muuriItem2Text: '#000000',
    muuriItem2Controls: '#38444f',
    muuriItem2ControlsText: '#ffffff',
    muuriItem3: '#ffffff',
    muuriItem3Text: '#000000',
    muuriItem3Controls: '#98a488',
    muuriItem3ControlsText: '#000000',
    muuriItem4: '#ffffff',
    muuriItem4Text: '#000000',
    muuriItem4Controls: '#fb8678',
    muuriItem4ControlsText: '#000000',
    muuriItem5: '#ffffff',
    muuriItem5Text: '#000000',
    muuriItem5Controls: '#806d66',
    muuriItem5ControlsText: '#000000',
    muuriItem6: '#ffffff',
    muuriItem6Text: '#000000',
    muuriItem6Controls: '#c5bfa7',
    muuriItem6ControlsText: '#000000',
    muuriItem7: '#ffffff',
    muuriItem7Text: '#000000',
    muuriItem7Controls: '#bec2c1',
    muuriItem7ControlsText: '#000000',
    muuriItem8: '#ffffff',
    muuriItem8Text: '#000000',
    muuriItem8Controls: '#9de1d7',
    muuriItem8ControlsText: '#000000',
    agenciesPanel: '#38444f',
    importAndUtilitiesPanel: '#98a488',
    billingPanel: '#fb8678',
    lendersPanel: '#806d66',
    loansPanel: '#c5bfa7',
    quickDataEntryPanel: '#bec2c1',
    reportsPanel: '#9de1d7',
  },
};

const mutations: MutationTree<ConfigStore> = {
  [UPDATE_CONFIG]: (state, payload) => {
    const newConfig = { ...state, ...payload };
    state.roundedPanels = newConfig.roundedPanels;
  },
}

const actions: ActionTree<ConfigStore, any> = {
  setRoundedPanel({ commit }, payload) {
    commit(UPDATE_CONFIG, { roundedPanels: payload });
  },
}

const dashboardModule: Module<ConfigStore, any> = {
  state: defaultState,
  mutations,
  actions,
};

export { dashboardModule as default };
