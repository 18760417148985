import Vue, { PluginObject } from 'vue';
import './filters/agencyCode';
import './filters/titleCase';
import './filters/date';
import './filters/username';
import './filters/arrayList';
import './filters/enum';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faTimes,
  faPlus,
  faDesktop,
  faBars,
  faCity,
  faUmbrella,
  faUsers,
  faMoneyBillAlt,
  faClock,
  faFileAlt,
  faFileImport,
  faDollarSign,
  faCog,
  faExternalLinkAlt,
  faPalette,
  faFont,
  faLock,
  faUnlock,
  faExclamationTriangle,
  faExclamationCircle,
  faEdit,
  faSearch,
  faSave,
  faAngleDown,
  faAngleUp,
  faTrashAlt,
  faInfo,
  faMapMarkedAlt,
  faPlusSquare,
  faMinus,
  faHistory,
  faUser,
  faUpload,
  faEllipsisH,
  faSignInAlt,
  faSignOutAlt,
  faPlay,
  faClone,
  faEnvelope,
  faEnvelopeOpenText,
  faDatabase,
  faCircle,
  faSquareFull,
  faUndo,
  faNewspaper,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { InfoControl, ReferenceChart, ChoroplethLayer } from 'vue-choropleth';
import VueCropper from 'vue-cropperjs';
import _ from 'lodash';
import axios from 'axios';
import VueCurrencyFilter from 'vue-currency-filter';
import VueCurrencyInput from 'vue-currency-input';
import VueCookies from 'vue-cookies';
import VueTheMask from 'vue-the-mask';
import VueClipboard from 'vue-clipboard2';

import vuetify from './plugins/vuetify';

import App from './App.vue';
import router from './router';
import store from './store/index';

// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'vue-json-pretty/lib/styles.css';

import { initializeLocalStorage } from './localStorage';
import { themes } from './exports/themes';

declare module 'vue/types/vue' {
  interface Vue {
    $baseUrl: string;
    $debug: boolean;
    $version: string;
  }
}

library.add(
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus,
  faDesktop,
  faBars,
  faCity,
  faUmbrella,
  faUsers,
  faMoneyBillAlt,
  faClock,
  faFileAlt,
  faFileImport,
  faDollarSign,
  faCog,
  faExternalLinkAlt,
  faPalette,
  faFont,
  faLock,
  faUnlock,
  faExclamationTriangle,
  faExclamationCircle,
  faEdit,
  faSearch,
  faSave,
  faAngleDown,
  faAngleUp,
  faTrashAlt,
  faInfo,
  faMapMarkedAlt,
  faPlusSquare,
  faMinus,
  faHistory,
  faUser,
  faUpload,
  faEllipsisH,
  faSignInAlt,
  faSignOutAlt,
  faPlay,
  faClone,
  faEnvelope,
  faEnvelopeOpenText,
  faDatabase,
  faCircle,
  faSquareFull,
  faUndo,
  faNewspaper,
  faBullhorn,
);

// Font Awesome
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Leaflet
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-info-control', InfoControl);
Vue.component('l-reference-chart', ReferenceChart);
Vue.component('l-choropleth-layer', ChoroplethLayer);

// Cropper
Vue.component('vue-cropper', VueCropper);

// Storage initialization
initializeLocalStorage(themes);

// TODO: Move this if it gets more complicated than just setting a URL
axios.defaults.baseURL = process.env.VUE_APP_CLIENT_API_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const requestPath = (error.request as XMLHttpRequest).responseURL;
    if (error.response.data.statusCode === 401 && !router.currentRoute.fullPath.includes('login') && !router.currentRoute.fullPath.includes('reset-password') && router.currentRoute.path !== '/' && !requestPath.includes('/user/me')) {
      router.push({ name: 'login', query: { redirect: router.currentRoute.path } });
    }

    return Promise.reject(error);
  },
);

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
});

Vue.use(VueCurrencyInput, {
  globalOptions: {
    locale: 'en-US',
    currency: 'USD',
    precision: 2,
  },
});

Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.use(VueClipboard);

// Set up envars
const Envars: PluginObject<never> = {
  install: (TheVue: typeof Vue) => {
    TheVue.prototype.$baseUrl = process.env.VUE_APP_CLIENT_API_URL;
    TheVue.prototype.$debug = process.env.VUE_APP_DEBUG === 'true';
    TheVue.prototype.$version = process.env.PACKAGE_VERSION;
  },
};

Vue.use(Envars);

Vue.$cookies.config('1d', undefined, process.env.VUE_APP_CLIENT_API_URL || '');

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.mixin({
  methods: {
    log: (str) => {
      console.log(str);
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
