enum IclOclType {
  oclWeb = 'OCL - Web',
  oclParcel = 'OCL - Parcel',
  oclPhone = 'OCL - Phone',
  iclWeb = 'ICL - Web',
  iclParcel = 'ICL - Parcel',
  iclPhone = 'ICL - Phone',
}

export { IclOclType as default };
