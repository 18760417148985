import User from './User';

export default class Verified<T = any> {
  value?: T;
  verified: boolean;
  verifiedBy?: User | string;
  verifiedOn?: Date;

  constructor(value?: T, verified?: boolean, verifiedBy?: User | string, verifiedOn?: Date) {
    this.value = value;
    this.verified = Boolean(verified);
    this.verifiedBy = verifiedBy;
    this.verifiedOn = verifiedOn;
  }

  verify(user: User | string, date: Date = new Date()) {
    this.verified = true;
    this.verifiedBy = user;
    this.verifiedOn = date;
  }
}
