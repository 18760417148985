const themes = [
  {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',

    // Above are built in colors
    dark: false,
    lightAndDarkBackground: 'white',
    lightOnlyBackground: 'white',
    darkOnlyBackground: '',
    oddRow: '#f3f3f3',
    evenRow: '#e4e4e4',
    notePadText: 'black',
    sideBarColor: '#38444f',
    sideBarTextColor: 'white',
    sideBarSelectionColor: '#88a2c4',
    textColor: '#353535',
    panelColor: '#c9c9c9',
    panelTextColor: '#000000',
    backgroundColor: '#ffffff',
    muuriItem1: '#ffffff',
    muuriItem1Text: '#000000',
    muuriItem1Controls: '#b5b0a9',
    muuriItem1ControlsText: '#000000',
    muuriItem2: '#ffffff',
    muuriItem2Text: '#000000',
    muuriItem2Controls: '#38444f',
    muuriItem2ControlsText: '#ffffff',
    muuriItem3: '#ffffff',
    muuriItem3Text: '#000000',
    muuriItem3Controls: '#98a488',
    muuriItem3ControlsText: '#000000',
    muuriItem4: '#ffffff',
    muuriItem4Text: '#000000',
    muuriItem4Controls: '#fb8678',
    muuriItem4ControlsText: '#000000',
    muuriItem5: '#ffffff',
    muuriItem5Text: '#000000',
    muuriItem5Controls: '#806d66',
    muuriItem5ControlsText: '#000000',
    muuriItem6: '#ffffff',
    muuriItem6Text: '#000000',
    muuriItem6Controls: '#c5bfa7',
    muuriItem6ControlsText: '#000000',
    muuriItem7: '#ffffff',
    muuriItem7Text: '#000000',
    muuriItem7Controls: '#bec2c1',
    muuriItem7ControlsText: '#000000',
    muuriItem8: '#ffffff',
    muuriItem8Text: '#000000',
    muuriItem8Controls: '#9de1d7',
    muuriItem8ControlsText: '#000000',
    agenciesPanel: '#38444f',
    importAndUtilitiesPanel: '#98a488',
    billingPanel: '#fb8678',
    lendersPanel: '#806d66',
    loansPanel: '#c5bfa7',
    quickDataEntryPanel: '#bec2c1',
    reportsPanel: '#9de1d7',
  },
  {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',

    // Above are built in colors
    dark: true,
    lightAndDarkBackground: 'black',
    lightOnlyBackground: '',
    darkOnlyBackground: 'black',
    oddRow: '#303030',
    evenRow: '#363636',
    notePadText: 'black',
    sideBarColor: '#38444f',
    sideBarTextColor: 'white',
    sideBarSelectionColor: '#88a2c4',
    textColor: '#353535',
    panelColor: '#c9c9c9',
    panelTextColor: '#000000',
    backgroundColor: '#ffffff',
    muuriItem1: '#ffffff',
    muuriItem1Text: '#000000',
    muuriItem1Controls: '#b5b0a9',
    muuriItem1ControlsText: '#000000',
    muuriItem2: '#ffffff',
    muuriItem2Text: '#000000',
    muuriItem2Controls: '#38444f',
    muuriItem2ControlsText: '#ffffff',
    muuriItem3: '#ffffff',
    muuriItem3Text: '#000000',
    muuriItem3Controls: '#98a488',
    muuriItem3ControlsText: '#000000',
    muuriItem4: '#ffffff',
    muuriItem4Text: '#000000',
    muuriItem4Controls: '#fb8678',
    muuriItem4ControlsText: '#000000',
    muuriItem5: '#ffffff',
    muuriItem5Text: '#000000',
    muuriItem5Controls: '#806d66',
    muuriItem5ControlsText: '#000000',
    muuriItem6: '#ffffff',
    muuriItem6Text: '#000000',
    muuriItem6Controls: '#c5bfa7',
    muuriItem6ControlsText: '#000000',
    muuriItem7: '#ffffff',
    muuriItem7Text: '#000000',
    muuriItem7Controls: '#bec2c1',
    muuriItem7ControlsText: '#000000',
    muuriItem8: '#ffffff',
    muuriItem8Text: '#000000',
    muuriItem8Controls: '#9de1d7',
    muuriItem8ControlsText: '#000000',
    agenciesPanel: '#38444f',
    importAndUtilitiesPanel: '#98a488',
    billingPanel: '#fb8678',
    lendersPanel: '#806d66',
    loansPanel: '#c5bfa7',
    quickDataEntryPanel: '#bec2c1',
    reportsPanel: '#9de1d7',
  },
];
// below is another "theme" from the very beginning of this project
// removed it when we added the dark theme

// {
//   primary: '#1976D2',
//   secondary: '#424242',
//   accent: '#82B1FF',
//   error: '#FF5252',
//   info: '#2196F3',
//   success: '#4CAF50',
//   warning: '#FFC107',
//   // Above are built in colors
//   sideBarColor: '#252E49',
//   sideBarTextColor: '#ffffff',
//   sideBarSelectionColor: '#FF8B00',
//   textColor: 'black',
//   panelColor: '#dbf3ff',
//   panelTextColor: '#000000',
//   backgroundColor: '#D8DBE2',
//   muuriItem1: '#ffffff',
//   muuriItem1Text: '#000000',
//   muuriItem1Controls: '#959ac6',
//   muuriItem1ControlsText: '#000000',
//   muuriItem2: '#ffffff',
//   muuriItem2Text: '#000000',
//   muuriItem2Controls: '#c7402d',
//   muuriItem2ControlsText: '#000000',
//   muuriItem3: '#ffffff',
//   muuriItem3Text: '#000000',
//   muuriItem3Controls: '#4acae5',
//   muuriItem3ControlsText: '#000000',
//   muuriItem4: '#ffffff',
//   muuriItem4Text: '#000000',
//   muuriItem4Controls: '#815eb1',
//   muuriItem4ControlsText: '#000000',
//   muuriItem5: '#ffffff',
//   muuriItem5Text: '#000000',
//   muuriItem5Controls: '#0068ba',
//   muuriItem5ControlsText: '#000000',
//   muuriItem6: '#ffffff',
//   muuriItem6Text: '#000000',
//   muuriItem6Controls: '#ff8750',
//   muuriItem6ControlsText: '#000000',
//   muuriItem7: '#ffffff',
//   muuriItem7Text: '#000000',
//   muuriItem7Controls: '#143046',
//   muuriItem7ControlsText: '#ffffff',
//   muuriItem8: '#ffffff',
//   muuriItem8Text: '#000000',
//   muuriItem8Controls: '#007f84',
//   muuriItem8ControlsText: '#000000',
//   quickLinksPanel: '#959ac6',
//   agenciesPanel: '#c7402d',
//   importAndUtilitiesPanel: '#4acae5',
//   billingPanel: '#815eb1',
//   lendersPanel: '#0068ba',
//   loansPanel: '#ff8750',
//   quickDataEntryPanel: '#143046',
//   reportsPanel: '#007f84',
// },
// eslint-disable-next-line import/prefer-default-export
export { themes };
