import {
  Module, MutationTree, ActionTree,
} from 'vuex';
import { defaults, isObject } from 'lodash';

import {
  SHOW_MESSAGE,
} from './mutationTypes';

type SnackbarPosition = 'top' | 'bottom' | 'left' | 'right' | 'center'

interface SnackbarAction {
  text: string,
  function: () => void,
}

export interface NotificationPayload {
  text: string,
  color?: string,
  timeout?: number,
  position?: SnackbarPosition,
  closeable?: boolean,
  actions?: SnackbarAction | SnackbarAction[],
}

export interface NotificationStore {
  text: string,
  color?: string,
  timeout?: number,
  position: SnackbarPosition,
  closeable?: boolean,
  actions?: SnackbarAction[],
}

const defaultState: NotificationStore = {
  text: '',
  position: 'bottom',
  color: null,
  timeout: null,
  closeable: false,
  actions: [],
};

const mutations: MutationTree<NotificationStore> = {
  [SHOW_MESSAGE]: (state, payload: NotificationPayload) => {
    const fullPayload = defaults(payload, defaultState);

    state.text = fullPayload.text;
    state.color = fullPayload.color;
    state.timeout = fullPayload.timeout;
    state.position = fullPayload.position;
    state.closeable = fullPayload.closeable;
    state.actions = Array.isArray(payload.actions) ? payload.actions : [payload.actions];
  },
}

const actions: ActionTree<NotificationStore, NotificationStore> = {
  async showError({ commit }, payload: NotificationPayload) {
    const mutationPayload = isObject(payload)
      ? payload
      : { text: payload };

    Object.assign(mutationPayload, {
      color: 'error',
      timeout: 5000,
    });

    commit(SHOW_MESSAGE, mutationPayload);
  },

  async showSuccess({ commit }, payload: string | NotificationPayload) {
    const mutationPayload = isObject(payload)
      ? payload
      : { text: payload };

    Object.assign(mutationPayload, {
      color: 'success',
      timeout: 5000,
    });

    commit(SHOW_MESSAGE, mutationPayload);
  },

  async showWarning({ commit }, payload: string | NotificationPayload) {
    const mutationPayload = isObject(payload)
      ? payload
      : { text: payload };

    Object.assign(mutationPayload, {
      color: 'warning',
      timeout: 5000,
    });

    commit(SHOW_MESSAGE, mutationPayload);
  },
}

const notificationModule: Module<NotificationStore, any> = {
  state: defaultState,
  mutations,
  actions,
};

export { notificationModule as default };
