import { DateTime } from 'luxon';

import IServiceUser, {
  IServiceScope, IServiceRole, IServiceUserLender, IServiceSimpleUser,
} from '@/services/api/models/IServiceUser';

export class UserLender {
  lenderId: string;
  lenderNumber: string;
  name: string;

  constructor(serviceObj: IServiceUserLender) {
    this.lenderId = serviceObj.lender_id;
    this.lenderNumber = serviceObj.lender_number;
    this.name = serviceObj.name;
  }
}

export class Scope {
  id: string;
  category: string;
  permission: string;

  constructor(serviceObj: IServiceScope) {
    this.id = serviceObj.id;
    this.category = serviceObj.category;
    this.permission = serviceObj.permission;
  }
}

export class Role {
  id: string;
  name: string;
  description?: string;
  scopes: Scope[] = [];

  constructor(serviceObj: IServiceRole) {
    this.id = serviceObj.id;
    this.name = serviceObj.name;
    this.description = serviceObj.description;

    if (serviceObj.scopes) {
      this.scopes = serviceObj.scopes.map((scope) => new Scope(scope));
    }
  }

  toString() {
    return this.name;
  }
}

export class SimpleUser {
  id: string;
  prefix?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  active: boolean = true;

  constructor(serviceObj: IServiceSimpleUser) {
    this.id = serviceObj.id;
    this.prefix = serviceObj.prefix;
    this.givenName = serviceObj.given_name;
    this.familyName = serviceObj.family_name;
    this.email = serviceObj.email;
    this.active = serviceObj.active;
  }

  toString(): string {
    return `${this.givenName} ${this.familyName}`
  }
}

export default class User {
  id: string = '';
  prefix?: string;
  givenName?: string;
  familyName?: string;
  email?: string;
  aboutMe?: string;
  hobbies: string;
  lenderId?: string;
  lenders?: UserLender[] = [];
  lastLogin?: Date;
  roles: Role[] = [];
  active: boolean = true;
  hours?: string;
  phone?: string;
  phoneExt?: string;
  fax?: string;
  birthday?: string;
  socialMedia?: string;
  notes?: string;

  constructor(id: IServiceUser | string, givenName: string = '', familyName: string = '', email?: string, lenderId?: string, roles?: IServiceRole[], lastLogin?: string, active: boolean = true, hours?: string, phone?: string, phoneExt?: string, fax?: string, birthday?: string, socialMedia?: string, notes?: string) {
    function isObject(obj: IServiceUser | string): obj is IServiceUser {
      return (obj as IServiceUser).id !== undefined;
    }

    if (!id) {
      return;
    }

    if (isObject(id)) {
      const serviceObj = id;
      this.id = serviceObj.id;
      this.prefix = serviceObj.prefix;
      this.givenName = serviceObj.given_name;
      this.familyName = serviceObj.family_name;
      this.email = serviceObj.email;
      this.aboutMe = serviceObj.about_me;
      this.hobbies = serviceObj.hobbies;
      this.lenderId = serviceObj.lender_id;
      this.lenders = serviceObj.lenders ? serviceObj.lenders.map((lender) => new UserLender(lender)) : [];
      if (serviceObj.lender) {
        this.lenders = [new UserLender(serviceObj.lender)]
      }
      this.lastLogin = serviceObj.last_login ? DateTime.fromISO(serviceObj.last_login).toJSDate() : undefined;
      this.active = serviceObj.active;
      this.hours = serviceObj.hours;
      this.phone = serviceObj.phone;
      this.phoneExt = serviceObj.phone_ext;
      this.fax = serviceObj.fax;
      this.birthday = serviceObj.birthday;
      this.socialMedia = serviceObj.social_media;
      this.notes = serviceObj.notes;
      this.roles = serviceObj.roles ? serviceObj.roles.map((role) => new Role(role)) : [];
      return;
    }

    this.id = id;
    this.givenName = givenName;
    this.familyName = familyName;
    this.email = email;
    this.lenderId = lenderId;
    this.lenders = [];
    this.lastLogin = lastLogin ? DateTime.fromISO(lastLogin).toJSDate() : undefined;
    this.active = active;
    this.hours = hours;
    this.phone = phone;
    this.phone = phoneExt;
    this.fax = fax;
    this.birthday = birthday;
    this.socialMedia = socialMedia;
    this.notes = notes;

    if (roles) {
      this.roles = roles.map((role) => new Role(role));
    }
  }

  static clone(instance: User): User {
    const copy = new (instance.constructor as { new (): User })();
    Object.assign(copy, instance);
    return copy;
  }

  toString(): string {
    return `${this.givenName} ${this.familyName}`
  }
}
