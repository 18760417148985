// eslint-disable-next-line import/prefer-default-export
export function initializeLocalStorage(themes: any[]) {
  if (!localStorage.contactTypes) {
    localStorage.contactTypes = JSON.stringify({
      Collector: '#4CAF50',
      Assessor: '#2196F3',
      Clerk: '#9c27b0',
    })
  }

  if (!localStorage.theme) {
    localStorage.theme = JSON.stringify(themes[0]);
  }
}
