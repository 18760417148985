










































































































































import {
  Component, Mixins,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

import UserPermissions from '@/mixins/UserPermissions.vue';
import Message from '@/entities/messages/Message';

@Component({
  name: 'side-bar',
})
export default class SideBar extends Mixins(UserPermissions) {
  @Getter('unreadMessages') unreadMessages!: Message[];
  @Action('logout') logout!: () => void;

  private isExpanded = false;
  private openCloseIcon = this.isExpanded ? 'times' : 'bars';

  // Computed
  get lenderLink(): string {
    if (this.isUser) {
      return '/lenders';
    }

    return this.user ? `/lenders/${this.user.lenderId}` : '/lenders';
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.openCloseIcon = this.isExpanded ? 'times' : 'bars';
  }

  async attemptLogout() {
    await this.logout();

    const routerParams = {
      name: 'login',
    };

    if (this.$debug) {
      Object.assign(routerParams, {
        query: { redirect: this.$router.currentRoute.path },
      });
    }

    this.$router.push(routerParams);
  }
}
