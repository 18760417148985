import { IServiceSimpleUser } from './IServiceUser';
import IServiceQuery from './IServiceQuery';
import IServiceFile from './IServiceFile';

export enum MessageType {
  fullBroadcast = 'broadcast_all',
  lenderBroadcast = 'broadcast_lender',
  internalBroadcast = 'broadcast_internal',
  news = 'news',
  direct = 'direct',
}

interface IServiceMessage {
  message_id: string,
  batch_id?: string,
  source: IServiceSimpleUser,
  target: IServiceSimpleUser,
  type: MessageType,
  body?: string,
  query?: IServiceQuery,
  read: boolean,
  files: IServiceFile[],
  created_date: string,
  updated_date?: string,
}

export { IServiceMessage as default };
