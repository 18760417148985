import cmp from 'semver-compare';
import { Store } from 'vuex';
import { AppStore } from '@/store';

import one from './1-change-dashboard-panel';
import two from './2-fix-links';

const migrations = [
  one,
  two,
];

class MigrationRunner {
  private newVersion: string;
  private oldVersion: string;
  private store: Store<AppStore>;

  private migrations: ((store: Store<AppStore>) => void)[] = [];

  constructor(oldVersion: string, newVersion: string, store: Store<AppStore>) {
    this.newVersion = newVersion;
    this.oldVersion = oldVersion;
    this.store = store;

    // Calculate what migrations to run
    if (this.newVersion === this.oldVersion) {
      return;
    }

    migrations.forEach((migration) => {
      if (cmp(oldVersion, migration.from) !== 1 && cmp(newVersion, migration.to) !== -1) {
        this.migrations.push(migration.migration);
      }
    });

    console.log(`Found ${this.migrations.length} migration(s) from version ${oldVersion} -> ${newVersion}`);
  }

  runMigrations(): boolean {
    if (this.migrations.length === 0) {
      return true;
    }

    try {
      this.migrations.forEach((migration) => migration(this.store));
      this.store.dispatch('setVersion', this.newVersion);
      return true;
    } catch (e) {
      return false;
    }
  }
}

export { MigrationRunner as default };
