import IServiceQuery, { IServiceQueryDefinition } from '@/services/api/models/IServiceQuery';
import { SimpleUser } from '@/entities/User';
import IQuery from '@/entities/queries/IQuery';

export default class Query implements IQuery {
  id: string;
  creator: SimpleUser;
  owner: SimpleUser;
  name: string;
  description?: string;
  query: IServiceQueryDefinition;
  tags: string[] = [];
  viewable: boolean = false;

  constructor(serviceObj: IServiceQuery) {
    this.id = serviceObj.query_id;

    this.creator = new SimpleUser(serviceObj.creator);
    this.owner = new SimpleUser(serviceObj.owner);

    this.name = serviceObj.name;
    this.description = serviceObj.description;
    this.query = serviceObj.query;

    if (!this.query.search) {
      this.query.search = [];
    }

    if (!this.query.select) {
      this.query.select = [];
    }

    if (serviceObj.tags) {
      this.tags = serviceObj.tags;
    }

    this.viewable = serviceObj.viewable;
  }
}
