import {
  Module,
} from 'vuex';

import defaultReportConfigs, { lenderReportConfigs, capitalReportConfigs, ReportConfig } from '@/store/reports/reports';

export interface ReportStore {
  reports: ReportConfig[],
  lenderReports: ReportConfig[],
  capitalReports: ReportConfig[],
}

const defaultState: ReportStore = {
  reports: defaultReportConfigs,
  lenderReports: lenderReportConfigs,
  capitalReports: capitalReportConfigs,
};

const reportModule: Module<ReportStore, any> = {
  state: defaultState,
};

export { reportModule as default };
