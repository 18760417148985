import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import userModule, { UserStore } from '@/store/user';
import dashboardModule, { DashboardStore } from '@/store/dashboard';
import configModule, { ConfigStore } from '@/store/config';
import lenderModule, { LenderStore } from '@/store/lenders';
import messageModule, { MessageStore } from '@/store/messages';
import metaModule, { MetaStore } from '@/store/meta';
import notificationModule, { NotificationStore } from '@/store/notifications';
import reportModule, { ReportStore } from '@/store/reports';

// TODO: Figure out a way to have these be required and work with top level reports
// Or make reports a module
export interface AppStore {
  lenders?: LenderStore,
  messages?: MessageStore,

  dashboard?: DashboardStore,
  user?: UserStore,
  config?: ConfigStore,
  meta?: MetaStore,

  notifications?: NotificationStore,
  reports?: ReportStore,
}

Vue.use(Vuex);

const vuexLocal = new VuexPersistence<AppStore>({
  storage: window.localStorage,
  modules: ['dashboard', 'config', 'meta', 'user'],
});

export default new Vuex.Store<AppStore>({
  modules: {
    lenders: lenderModule,
    messages: messageModule,
    user: userModule,
    dashboard: dashboardModule,
    config: configModule,
    meta: metaModule,
    notifications: notificationModule,
    reports: reportModule,
  },
  plugins: [vuexLocal.plugin],
});
