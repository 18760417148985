import {
  Module, GetterTree, MutationTree, ActionTree,
} from 'vuex';

import Message from '@/entities/messages/Message';
import UserService, { MessagePost } from '@/services/users';

import { MessageType } from '@/services/api/models/IServiceMessage';
import {
  SET_INBOX, SET_SENT, SET_INBOX_LOADING, SET_SENT_LOADING, MARK_READ, DELETE_MESSAGE, ADD_MESSAGE, DELETE_BATCH,
} from './mutationTypes';

export interface MessageStore {
  inbox: Message[],
  sent: Message[],

  inboxLoading: boolean,
  sentLoading: boolean,
}

const userService = new UserService();

const defaultState: MessageStore = {
  inbox: [],
  sent: [],

  inboxLoading: false,
  sentLoading: false,
};

const getters: GetterTree<MessageStore, any> = {
  getMessageById: (state) => (id: string) => {
    const combinedMessages: Message[] = ([] as Message[]).concat(state.inbox, state.sent);
    combinedMessages.find((message) => message.id === id)
  },
  newsMessages: (state) => state.inbox.filter((message) => message.type === MessageType.news),
  inboxMessages: (state) => state.inbox.filter((message) => message.type !== MessageType.news),
  unreadMessages: (state) => state.inbox.filter((message) => !message.read),
  unreadNewsMessages: (state, theGetters) => theGetters.newsMessages.filter((message: Message) => !message.read),
  unreadInboxMessages: (state, theGetters) => theGetters.inboxMessages.filter((message: Message) => !message.read),
}

const mutations: MutationTree<MessageStore> = {
  [SET_INBOX]: (state, payload) => {
    state.inbox = payload;
  },
  [SET_SENT]: (state, payload) => {
    state.sent = payload;
  },
  [SET_INBOX_LOADING]: (state, payload) => {
    state.inboxLoading = payload;
  },
  [SET_SENT_LOADING]: (state, payload) => {
    state.sentLoading = payload;
  },
  [MARK_READ]: (state, payload) => {
    const messageToMark = state.inbox.find((message) => message.id === payload);

    if (messageToMark) {
      messageToMark.read = true;
    }
  },
  [ADD_MESSAGE]: (state, payload: Message) => {
    state.sent.push(payload);
    state.sent.splice(0, 0, payload);

    if (payload.type === 'news') {
      state.inbox.unshift(payload);
    }
  },
  [DELETE_MESSAGE]: (state, payload) => {
    const deleteIndex = state.inbox.findIndex((message) => message.id === payload);

    if (deleteIndex !== -1) {
      state.inbox.splice(deleteIndex, 1);
    }
  },
  [DELETE_BATCH]: (state, payload) => {
    let deleteIndex = state.sent.findIndex((message) => message.batchId === payload);

    if (deleteIndex !== -1) {
      state.sent.splice(deleteIndex, 1);
    }

    deleteIndex = state.inbox.findIndex((message) => message.batchId === payload);

    if (deleteIndex !== -1) {
      state.inbox.splice(deleteIndex, 1);
    }
  },
}

const actions: ActionTree<MessageStore, any> = {
  async fetchMessages({ commit }) {
    try {
      commit(SET_INBOX_LOADING, true);
      const inboxResponse = await userService.getUserInbox();

      commit(SET_SENT_LOADING, true);
      const sentResponse = await userService.getUserSent();

      commit(SET_INBOX, inboxResponse);
      commit(SET_SENT, sentResponse);
    } catch (e) {
      console.log('Could not retrieve messages.');
      console.log(e);
    } finally {
      commit(SET_INBOX_LOADING, false);
      commit(SET_SENT_LOADING, false);
    }
  },

  async markAsRead({ commit }, message) {
    try {
      await userService.markAsRead(message);
      commit(MARK_READ, message);
    } catch (e) {
      console.log('Could not mark message as read.');
    }
  },

  async deleteMessage({ commit }, id: string) {
    try {
      await userService.deleteMessage(id);
      commit(DELETE_MESSAGE, id);
    } catch (e) {
      console.log('Could not delete message.');
    }
  },

  async sendMessage({ commit }, payload: { message: MessagePost, files: File[] }) {
    try {
      await userService.addMessage(payload.message, payload.files);
      this.dispatch('fetchMessages');
    } catch (e) {
      console.log(e)
      console.log('Could not send message.');
    }
  },

  async deleteSentBatch({ commit }, id: string) {
    try {
      await userService.deleteSentBatch(id);
      commit(DELETE_BATCH, id);
    } catch (e) {
      console.log('Could not delete message.');
    }
  },
}

const lenderModule: Module<MessageStore, any> = {
  state: defaultState,
  getters,
  mutations,
  actions,
};

export { lenderModule as default };
