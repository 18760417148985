import {
  Module, MutationTree, ActionTree,
} from 'vuex';
import { UPDATE_VERSION } from './mutationTypes';

export interface MetaStore {
  version: string,
}

const defaultState: MetaStore = {
  version: '0.0.0',
};

const mutations: MutationTree<MetaStore> = {
  [UPDATE_VERSION]: (state, payload) => {
    state.version = payload;
  },
}

const actions: ActionTree<MetaStore, any> = {
  setVersion({ commit }, payload) {
    commit(UPDATE_VERSION, payload);
  },
}

const metaModule: Module<MetaStore, any> = {
  state: defaultState,
  mutations,
  actions,
};

export { metaModule as default };
