import Vue from 'vue';

Vue.filter('agencyCode', (value: string) => {
  const positions: number[] = [2, 6, 9];

  positions.forEach((position, index) => {
    if (value.length > position) {
      value = `${value.slice(0, position - 1)}-${value.slice(position + value.length)}`;
    }

    return value;
  });
});
