import {
  Module, GetterTree, MutationTree, ActionTree,
} from 'vuex';

import Lender from '@/entities/Lender';
import LenderService from '@/services/lenders';
import {
  SET_LENDERS, SET_LENDERS_LOADING,
} from './mutationTypes';

export interface LenderStore {
  lenders: Lender[],
  loading: boolean,
}

const lenderService = new LenderService();

const defaultState = {
  lenders: [] as Lender[],
  loading: false,
};

const getters: GetterTree<LenderStore, any> = {
  getLenderById: (state) => (id: string) => state.lenders.find((lender) => lender.id === id),
}

const mutations: MutationTree<LenderStore> = {
  [SET_LENDERS]: (state, payload) => {
    state.lenders = payload;
  },
  [SET_LENDERS_LOADING]: (state, payload) => {
    state.loading = payload;
  },
}

const actions: ActionTree<LenderStore, any> = {
  async fetchLenders({ commit }) {
    try {
      commit(SET_LENDERS_LOADING, true)
      const response = await lenderService.getAllLenders();

      commit(SET_LENDERS, response.lenders);
    } catch (e) {
      console.log('Could not retrieve lenders.');
    } finally {
      commit(SET_LENDERS_LOADING, false)
    }
  },
}

const lenderModule: Module<LenderStore, any> = {
  state: defaultState,
  getters,
  mutations,
  actions,
};

export { lenderModule as default };
