import { Store } from 'vuex';
import { cloneDeep } from 'lodash';

import { AppStore } from '@/store';

const migration = {
  from: '0.0.0',
  to: '0.1.0',
  migration: (store: Store<AppStore>) => {
    if (!store.state.dashboard.panels) {
      store.dispatch('resetPanels');
    }
    const currentPanels = cloneDeep(store.state.dashboard.panels ? store.state.dashboard.panels : []);
    const panelIndex = currentPanels.findIndex((panel) => panel.component === 'Messages');

    if (panelIndex !== -1) {
      const messagesPanel = currentPanels[panelIndex];
      messagesPanel.component = 'Inbox';
      store.dispatch('savePanels', currentPanels);
    }
  },
};

export { migration as default };
