



































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'notification-snackbar',
})
export default class NotificationSnackbar extends Vue {
  @State((state) => state.notifications.text) readonly text!: string;
  @State((state) => state.notifications.color) readonly color!: string;
  @State((state) => state.notifications.timeout) readonly timeout!: string;
  @State((state) => state.notifications.position) readonly position!: string;
  @State((state) => state.notifications.closeable) readonly closeable!: boolean;
  @State((state) => state.notifications.actions) readonly actions!: { text: string, function: () => void }[];

  private show: boolean = false;

  @Watch('text')
  onTextChanged(val: string, oldVal: string) {
    this.show = true;
  }
}
