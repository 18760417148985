import {
  Module, MutationTree, ActionTree,
} from 'vuex';
import { cloneDeep } from 'lodash';

import User from '@/entities/User';

import {
  ADD_PANEL, SAVE_PANELS,
} from './mutationTypes';

export interface Panel {
  width: number,
  height: number,
  component: string,
  route?: string,
  id?: string,
}

export interface DashboardStore {
  panels: Panel[],
}

function hasRole(user: User, name: string): boolean {
  return user ? user.roles.findIndex((role) => role.name === name) !== -1 : false;
}

// Capital Admin and Capital User
// TODO: Component property here, VERY brittle
const capitalDashboard: DashboardStore = {
  panels: [{
    component: 'inbox',
    route: 'messages',
    width: 50,
    height: 25,
  }, {
    component: 'loans',
    route: 'loans',
    width: 50,
    height: 50,
  }, {
    component: 'reports',
    route: 'reports',
    width: 25,
    height: 25,
  }, {
    component: 'quick-launcher',
    width: 25,
    height: 25,
  }, {
    component: 'lenders',
    route: 'lenders',
    width: 50,
    height: 50,
  }, {
    component: 'agencies',
    route: 'agencies',
    width: 50,
    height: 50,
  }],
};

const lenderDashboard: DashboardStore = {
  panels: [{
    component: 'inbox',
    route: 'messages',
    width: 50,
    height: 25,
  }, {
    component: 'loans',
    route: 'loans',
    width: 50,
    height: 50,
  }, {
    component: 'reports',
    route: 'reports',
    width: 50,
    height: 25,
  }, {
    component: 'lenders-detail',
    route: 'lenders-detail',
    width: 50,
    height: 50,
  }, {
    component: 'agencies',
    route: 'agencies',
    width: 50,
    height: 50,
  }],
};

const mutations: MutationTree<DashboardStore> = {
  [ADD_PANEL]: (state, payload) => {
    state.panels.push(payload);
  },
  [SAVE_PANELS]: (state, payload) => {
    state.panels = payload;
  },
}

const actions: ActionTree<DashboardStore, any> = {
  async addPanel({ commit }, payload) {
    commit(ADD_PANEL, payload);
  },

  async savePanels({ commit }, payload) {
    commit(SAVE_PANELS, payload);
  },

  async resetPanels({ commit, rootState }) {
    if (hasRole(rootState.user.user, 'Lender User') || hasRole(rootState.user.user, 'Lender Admin')) {
      const lenderPanels = cloneDeep(lenderDashboard.panels);
      const lenderDetailPanel = lenderPanels.find((panel) => panel.component === 'lenders-detail');
      lenderDetailPanel.id = rootState.user.user.lenderId;
      commit(SAVE_PANELS, lenderPanels);
      return;
    }

    commit(SAVE_PANELS, capitalDashboard.panels);
  },
}

const dashboardModule: Module<DashboardStore, any> = {
  mutations,
  actions,
};

export { dashboardModule as default };
