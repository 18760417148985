import { DateTime } from 'luxon';

import IServiceQuery from '@/services/api/models/IServiceQuery';
import { SimpleUser } from '@/entities/User';
import IServiceMessage, { MessageType } from '@/services/api/models/IServiceMessage';

import IFile from '@/entities/IFile';

export default class Message {
  id: string;
  batchId?: string;
  source: SimpleUser;
  target?: SimpleUser;
  type: MessageType;
  body?: string;
  query?: IServiceQuery;
  files: IFile[] = [];
  read: boolean;
  createdDate: Date;
  updatedDate?: Date;

  constructor(serviceObj: IServiceMessage) {
    this.id = serviceObj.message_id;
    this.batchId = serviceObj.batch_id;

    this.source = new SimpleUser(serviceObj.source);

    if (serviceObj.target) {
      this.target = new SimpleUser(serviceObj.target);
    }

    this.type = serviceObj.type;
    this.body = serviceObj.body;
    this.query = serviceObj.query ? serviceObj.query : undefined;

    if (this.query && this.query.query && !this.query.query.search) {
      this.query.query.search = [];
    }

    if (this.query && this.query.query && !this.query.query.select) {
      this.query.query.select = [];
    }

    if (serviceObj.files) {
      this.files = serviceObj.files;
    }

    this.read = serviceObj.read;

    this.createdDate = DateTime.fromISO(serviceObj.created_date).toJSDate();
    this.updatedDate = serviceObj.updated_date ? DateTime.fromISO(serviceObj.updated_date).toJSDate() : undefined;
  }
}
