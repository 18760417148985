import Vue from 'vue';
import Router, { Route } from 'vue-router';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';

import * as config from '@/reportConfigs.json';
import { startCase } from 'lodash';

Vue.use(Router);

interface SearchProperty {
  text?: string,
  field: string,
  type: string,
  roles?: string[],
  options?: string[],
}

const trackId = (entityType: String) => (to: Route, from: Route, next: Function) => {
  if (!localStorage.recentlyVisited) {
    localStorage.recentlyVisited = JSON.stringify([]);
  }

  const recentlyVisited: any[] = JSON.parse(localStorage.recentlyVisited);

  const existingEntry = recentlyVisited.find((entry) => entry.type === entityType && entry.id === to.params.id);
  const existingEntryIndex = recentlyVisited.findIndex((entry) => entry.type === entityType && entry.id === to.params.id);

  if (existingEntryIndex !== -1) {
    recentlyVisited.splice(existingEntryIndex, 1);
    recentlyVisited.push(existingEntry);

    localStorage.recentlyVisited = JSON.stringify(recentlyVisited);
  } else {
    if (recentlyVisited.length >= 10) {
      recentlyVisited.shift();
    }

    recentlyVisited.push({
      type: entityType,
      timestamp: DateTime.local(),
      id: to.params.id,
    });

    localStorage.recentlyVisited = JSON.stringify(recentlyVisited);
  }

  next();
};

export const configMapBuilder = (configs: any | any[]): Map<string, (string | SearchProperty)[]> => {
  let builderConfigs: any[];
  if (Array.isArray(configs)) {
    builderConfigs = configs;
  } else {
    builderConfigs = [configs];
  }

  const fullConfig = builderConfigs.reduce((map, builderConfig) => {
    const keys = Object.keys(builderConfig);
    keys.forEach((key) => {
      map.set(key, builderConfig[key]);
    });
    return map;
  }, new Map<string, (string | SearchProperty)[]>()); // eslint-disable-line

  return fullConfig;
}

const router: Router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/login/Login.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "login" */ './views/login/ResetPassword.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/settings',
      name: 'settings',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "admin" */ './views/Settings.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/agencies',
      name: 'agencies',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "agency" */ './views/agencies/Agencies.vue'),
      children: [
        {
          path: 'create',
          name: 'create-agency',
          component: () => import(/* webpackChunkName: "agency" */ './views/agencies/AgenciesDetail.vue'),
        },
        {
          path: ':id',
          name: 'agencies-detail',
          beforeEnter: trackId('agency'),
          component: () => import(/* webpackChunkName: "agency" */ './views/agencies/AgenciesDetail.vue'),
        },
      ],
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/billing',
      name: 'billing',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "billing" */ './views/billing/Billing.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/import-utilities',
      name: 'import-utilities',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "import" */ './views/ImportUtilities.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/lenders',
      name: 'lenders',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "lender" */ './views/lenders/Lenders.vue'),
      children: [
        {
          path: ':id',
          name: 'lenders-detail',
          beforeEnter: trackId('lender'),
          component: () => import(/* webpackChunkName: "lender" */ './views/lenders/LendersDetail.vue'),
        },
      ],
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/loans',
      name: 'loans',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "loan" */ './views/loans/Loans.vue'),
      children: [
        {
          path: ':id',
          name: 'loan-detail',
          beforeEnter: trackId('loan'),
          component: () => import(/* webpackChunkName: "agency" */ './views/loans/LoansDetail.vue'),
        },
      ],
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/quick-entry',
      name: 'quick-entry',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "add" */ './views/quickEntry/QuickEntryCenter.vue'),
      props: (route) => ({ type: route.query.type }),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/agency-label-report',
      name: 'agency-label-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/AgencyLabelReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/add-parcel-report',
      name: 'add-parcel-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/AddParcelReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/add-tax-office-report',
      name: 'add-tax-office-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/AddTaxOfficeReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/pnv-report',
      name: 'parcels-not-verified-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/PnvReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/parcel-format-flag',
      name: 'parcel-format-flag',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/ParcelFormatFlagReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/delinquent-memo-bill-report',
      name: 'delinquent-memo-bill-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/DelinquentMemoBillReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/escrow-balance-sheet-report',
      name: 'escrow-balance-sheet-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/EscrowBalanceSheetReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/escrow-id-sheet-report',
      name: 'escrow-id-sheet-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/EscrowIDSheetReport.vue'),
      meta: {
        isPanel: false,
        title: 'Escrow ID Sheet Report',
      },
    },
    {
      path: '/escrow-memo-bill-report',
      name: 'escrow-memo-bill-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/EscrowMemoBillReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/tax-bill-request',
      name: 'tax-bill-request',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/TaxBillRequestReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/tax-data-sheet',
      name: 'tax-data-sheet',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/TaxDataSheetReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/agency-data-report',
      name: 'agency-data-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/builder/QueryBuilder.vue'),
      props: {
        title: 'Agency Data Report',
        selections: configMapBuilder(config.agencyDataReport),
      },
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/loan-data-report',
      name: 'loan-data-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/builder/QueryBuilder.vue'),
      props: {
        title: 'Loan Data Report',
        selections: configMapBuilder(config.loanDataReport),
      },
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/lender-data-report',
      name: 'lender-data-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/builder/QueryBuilder.vue'),
      props: {
        title: 'Lender Data Report',
        selections: configMapBuilder(config.lenderDataReport),
      },
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/pack-list-report',
      name: 'pack-list-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/PackListReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/query-builder',
      name: 'query-builder',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/builder/QueryBuilder.vue'),
      props: {
        title: 'Query Builder',
        selections: configMapBuilder([config.lenderDataReport, config.agencyDataReport, config.loanDataReport]),
      },
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/queries/:id',
      name: 'query-detail',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/builder/QueryDetail.vue'),
      props: {
        title: 'Query Builder',
        selections: configMapBuilder([config.lenderDataReport, config.agencyDataReport, config.loanDataReport]),
      },
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/audit-report',
      name: 'audit-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/AuditReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/audit-search-report',
      name: 'audit-search-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/AuditSearchReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/modify-reported-date-report',
      name: 'modify-reported-date-report',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/reports/ModifyReportedDateReport.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/reports',
      name: 'reports',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './components/Reports.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/history',
      name: 'history',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/history/History.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "profile" */ './views/profile/Profile.vue'),
      children: [
        {
          path: ':id',
          name: 'profile',
          beforeEnter: trackId('profile'),
          component: () => import(/* webpackChunkName: "profile" */ './views/profile/Profile.vue'),
        },
      ],
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/messages',
      name: 'messages',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "message" */ './views/messages/Messages.vue'),
      meta: {
        isPanel: true,
      },
    },
    {
      path: '/import',
      name: 'import',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/import/ImportCenter.vue'),
      meta: {
        isPanel: false,
      },
    },
    {
      path: '/403',
      name: 'apiError',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "report" */ './views/errors/ApiError.vue'),
      meta: {
        isPanel: false,
      },
    },
  ],
});

router.onError((err: any) => {
  console.log('Router error detected!', err);

  function isAxiosResponseError(error: Error): error is AxiosError {
    return (error as AxiosError).response !== undefined;
  }

  if ((err as AxiosError).response) {
    const { response } = err as AxiosError;
    console.log(response);

    if (response && response.status === 401) {
      router.push({ name: 'login', query: { redirect: err.intendedRoute ? err.intendedRoute.path : '' } });
    } else if (response && response.status === 403) {
      router.push({ name: 'apiError' });
    }
  } else {
    router.push({ name: 'login', query: { redirect: router.currentRoute.path } });
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || startCase(to.name);
  next();
});

export default router;
