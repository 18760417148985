import Axios, { AxiosResponse } from 'axios';

import IServiceUser from '@/services/api/models/IServiceUser';
import IServiceQuery from '@/services/api/models/IServiceQuery';
import IServiceMessage from '@/services/api/models/IServiceMessage';
import { JsonPatchPayload } from '@/helpers/vuelidateToPatch';

type UserUpdate = Partial<Omit<IServiceUser, 'last_login' | 'person_id'>>;

export default {
  async login(username: string, password: string): Promise<void> {
    await Axios.post('/login', null, {
      auth: {
        username,
        password,
      },
    });
  },

  async logout(): Promise<void> {
    await Axios.delete('/login');
  },

  async resetPassword(email: string): Promise<void> {
    await Axios.post('/reset-password', { email });
  },

  async getOwnUser(): Promise<IServiceUser> {
    const response = await Axios.get<IServiceUser>('/users/me');

    return response.data;
  },

  async getUsers(search?: string): Promise<IServiceUser[]> {
    const params = {};

    if (search) {
      Object.assign(params, {
        q: search,
      });
    }

    const response = await Axios.get<IServiceUser[]>('/users', { params });

    return response.data;
  },

  async getUserById(id: string): Promise<IServiceUser> {
    const response = await Axios.get<IServiceUser>(`/users/${id}`);

    return response.data;
  },

  async createUser(payload: Partial<IServiceUser>): Promise<IServiceUser> {
    const response = await Axios.post<IServiceUser, AxiosResponse<IServiceUser>>('/users', payload);
    return response.data;
  },

  async updateUser(id: string, payload: UserUpdate): Promise<IServiceUser> {
    const response = await Axios.put<IServiceUser>(`/users/${id}`, payload);
    return response.data;
  },

  async deleteUser(id: string): Promise<IServiceUser> {
    const response = await Axios.delete<IServiceUser>(`/users/${id}`);
    return response.data;
  },

  async patchOwnUser(payload: JsonPatchPayload): Promise<IServiceUser> {
    const response = await Axios.patch<IServiceUser>('/users/me', payload)
    return response.data;
  },

  async patchUser(id: string, payload: JsonPatchPayload): Promise<IServiceUser> {
    const response = await Axios.patch<IServiceUser>(`/users/${id}`, payload)
    return response.data;
  },

  async uploadFile(name: string, formData: FormData): Promise<void> {
    await Axios.post<void, AxiosResponse<void>>(`/users/me/files/${name}`, formData);
  },

  async logoutUser(id: string): Promise<void> {
    await Axios.post<void>(`/users/${id}/logout`);
  },

  async getAllUserQueries(): Promise<IServiceQuery[]> {
    const response = await Axios.get<IServiceQuery[]>('/users/me/queries');

    return response.data;
  },

  async getUserQuery(id: string): Promise<IServiceQuery> {
    const response = await Axios.get<IServiceQuery>(`/users/me/queries/${id}`);
    return response.data;
  },

  async updateUserQuery(id: string, payload: JsonPatchPayload): Promise<IServiceQuery> {
    const response = await Axios.patch<IServiceQuery>(`/users/me/queries/${id}`, payload);
    return response.data;
  },

  async addUserQuery(payload: any): Promise<IServiceQuery> {
    const response = await Axios.post<any, AxiosResponse<IServiceQuery>>('/users/me/queries', payload);
    return response.data;
  },

  async deleteUserQuery(id: string): Promise<void> {
    await Axios.delete(`/users/me/queries/${id}`);
  },

  async getUserInbox(): Promise<IServiceMessage[]> {
    const response = await Axios.get<IServiceMessage[]>('/users/me/messages/inbox');
    return response.data;
  },

  async getUserSent(): Promise<IServiceMessage[]> {
    const response = await Axios.get<IServiceMessage[]>('/users/me/messages/sent');
    return response.data;
  },

  async deleteMessage(id: string): Promise<void> {
    await Axios.delete(`/users/me/messages/${id}`);
  },

  async deleteSentBatch(id: string): Promise<void> {
    await Axios.delete(`/users/me/messages/sent/batches/${id}`);
  },

  async markAsRead(id: string, read: boolean = true): Promise<IServiceMessage> {
    const response = await Axios.put(`/users/me/messages/${id}/read`, {
      read,
    });

    return response.data;
  },

  async addMessage(payload: any): Promise<IServiceMessage> {
    const response = await Axios.post<any, AxiosResponse<IServiceMessage>>('/messages', payload);
    return response.data;
  },
};
